// eslint-disable no-else-return
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import clsx from 'clsx'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import moment from 'moment'
import Banner1 from '../../../assets/images/topbanner-01.png'
import Banner3 from '../../../assets/images/1290 X 120_03.png'
import seol from './seol.png'

const banners: Array<{
  linkUrl: string | null
  imageUrl: string
  startDate: string | null
  endDate: string | null
}> = [
  {
    linkUrl: null,
    imageUrl: seol,
    startDate: '2025-01-15 09:00',
    endDate: '2025-01-31 23:59',
  },
  {
    linkUrl: 'https://www.aipark.ai/aivatar/9',
    imageUrl: Banner1,
    startDate: null,
    endDate: '2025-01-31 23:59:59',
  },
  {
    linkUrl:
      'https://www.aipark.ai/reference?utm_source=banner&utm_medium=website&utm_campaign=workspace-banner-2411C&utm_content=cta-reference',
    imageUrl: Banner3,
    startDate: null,
    endDate: null,
  },
]

export default function TopBanner() {
  // const { i18n } = useTranslation()
  // const dialog = useDialog()

  const slideStyle = clsx('')

  const visibleBanners = banners.filter((banner) => {
    if (banner.startDate && banner.endDate) {
      return moment().isBetween(moment(banner.startDate), moment(banner.endDate))
    }
    if (banner.startDate && !banner.endDate) {
      return moment().isSameOrAfter(moment(banner.startDate))
    }
    if (!banner.startDate && banner.endDate) {
      return moment().isSameOrBefore(moment(banner.endDate))
    }
    return true
  })

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      pagination
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      loop
      style={{
        ['--swiper-theme-color' as any]: '#fff',
        ['--swiper-navigation-size' as any]: '32px',
        borderRadius: 8,
        height: 120,
      }}
    >
      {visibleBanners.map((banner) => (
        <SwiperSlide key={banner.imageUrl} className={slideStyle}>
          <a
            // eslint-disable-next-line no-script-url
            href={banner.linkUrl ?? '#'}
            target={banner.linkUrl ? '_blank noreferrer' : undefined}
            aria-label="banner"
            className="block h-full w-full"
          >
            <img src={banner.imageUrl} alt="" className="h-full w-full object-cover object-center" />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
