import { useTranslation } from 'react-i18next'
import { useCurrentWorkspaceDetailQuery } from '../../../hooks/queries'
import PlanLink from './PlanLink'

type Props = {
  isCurrent: boolean
  planId: number
  price: number
}

export default function CheckoutLink({ isCurrent, planId, price }: Props) {
  const { t } = useTranslation('plan-info-modal')
  const { data: currentWorkspace } = useCurrentWorkspaceDetailQuery()

  const href = `/workspace/${currentWorkspace?.id}/subscription/checkout?plan=${planId}`

  // 현재플랜이다 ? 무조건 현재플랜 이라는 표시 보여주기
  if (isCurrent) {
    return <PlanLink disabled>{t('현재 플랜')}</PlanLink>
  }

  if (price === 0) return null
  return <PlanLink href={href}>{t('플랜 변경')}</PlanLink>
}
