import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
// import useURI from '../../../hooks/useURI'
import Banner1 from '../../../../assets/images/230 X 120_01.png'
import Banner2 from '../../../../assets/images/230 X 120_02.png'
import useDialog from '../../../../hooks/useDialog'
import SubscriptionPlanDialog from '../../../SubscriptionPlan/SubscriptionPlanDialog'
// import useAuth from '../../../hooks/useAuth'

const bannerImages = [Banner1, Banner2]

function Banner({ className = '' }) {
  // const { user } = useAuth()
  // const { uri } = useURI()
  const [bannerImage, setBannerImage] = useState<string | null>(null)
  const dialog = useDialog()

  // const queryParams = new URLSearchParams({
  //   email: user.email,
  //   firstname: user.name,
  //   lastname: user.lastName,
  // })
  // const linkTo = `${uri['contact-us']}?${queryParams}`

  const bannerStyle = twMerge('bg-main-3 w-[230px] h-[120px] rounded-[10px] object-cover', className)

  useEffect(() => {
    setBannerImage(bannerImages[Math.floor(Math.random() * 2)] ?? null)
  }, [])

  return (
    bannerImage && (
      <button type="button" onClick={() => dialog.open(<SubscriptionPlanDialog expanded />)}>
        <img className={bannerStyle} src={bannerImage} alt="nav-bar-banner" />
      </button>
    )
  )
}

export default Banner
