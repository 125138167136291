import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  validBoxesByCategoryWithFilterState,
  validSelectedBoxesByCategoryWithFilterState,
} from '../../recoil/texteditor/editor/selectors'
import { SENTENCEBOX_CATEGORY } from '../../recoil/texteditor/sentenceBox/atoms'
import useConstantProperties from '../utils/useConstantProperties'

import AivatarClient from '../AivatarClient'
import { CALL_STATE } from '../constants'
import { currentSelectedCharacterState, currentSelectedDressState } from '../../recoil/workspace/selectors'
import { projectValueState } from '../../recoil/project/selectors'

export default function useExportVideo() {
  const { workspaceId, projectId } = useParams()
  /** Recoils */
  const generalViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.GENERAL,
    }),
  )

  const generalSelectedBoxes = useRecoilValue(
    validSelectedBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.GENERAL,
    }),
  )

  const optionViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.OPTION,
    }),
  )
  const optionSelectedBoxes = useRecoilValue(
    validSelectedBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.OPTION,
    }),
  )

  const audioViewerBoxes = useRecoilValue(
    validBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
    }),
  )

  const audioSelectedBoxes = useRecoilValue(
    validSelectedBoxesByCategoryWithFilterState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
    }),
  )

  const avatarId = useRecoilValue(projectValueState({ key: 'avatarId' }))
  // TODO dress도 seqId(프로젝트 상태)로 보내면 좀더 일관성있고 깔끔할것 같은데,
  // TODO 아직은 뭐가 맞는지 확신이 안 선다. 프로젝트 상태 이외의 것도 이미 Request Body에 많이 들어가있기 때문에.
  // TODO 만약 코드를 고치자면 dress쪽을 좀 더 손 보면 괜찮을것같다.
  const currentSelectedDress = useRecoilValue(currentSelectedDressState)
  const backgroundColor = useRecoilValue(projectValueState({ key: 'backgroundColor' }))
  const currentSelectedCharacter = useRecoilValue(currentSelectedCharacterState)

  const isValidWorkspaceStates = useMemo(() => {
    if (!avatarId) return false
    if (!currentSelectedDress) return false
    return true
  }, [avatarId, currentSelectedDress])

  const firstInterval = useRecoilValue(projectValueState({ key: 'firstInterval' }))

  const [res, setRes] = useState(null)
  const [apiState, setApiState] = useState(CALL_STATE.NEW)
  const { isCallSuccess, isCallError, isLoading } = useConstantProperties(apiState)

  const getBody = ({ filename, filetype, isAll }) => {
    const attributes = []

    const arrayLength = isAll ? generalViewerBoxes.length : generalSelectedBoxes.length
    let duration = 0
    for (let i = 0; i < arrayLength; i += 1) {
      const sentenceBox = isAll ? generalViewerBoxes[i] : generalSelectedBoxes[i]
      const sentenceBoxOption = isAll ? optionViewerBoxes[i] : optionSelectedBoxes[i]
      const sentenceBoxAudio = isAll ? audioViewerBoxes[i] : audioSelectedBoxes[i]
      const { voiceId, space, pitch, speed, volume, language } = sentenceBoxOption
      const attribute = {
        text: sentenceBox.text,
        projectId,
        voiceId,
        sentenceId: sentenceBox.sentenceId,
        audioId: sentenceBoxAudio.audioId,
        space,
        pitch,
        speed,
        volume,
        language,
      }
      duration += sentenceBoxAudio.estimatedDurationMS
      attributes.push(attribute)
    }

    const { actingVideoId, deepLearningModelId } = currentSelectedDress
    const body = {
      aivatarId: avatarId,
      actingVideoId,
      deepLearningModelId,
      workspaceId,
      projectId,
      credit: Math.floor((duration / 1000) * (currentSelectedCharacter.styleType === 'BIDIMENSIONAL' ? 3 : 10)),
      before: firstInterval ?? 0,
      filename,
      filetype,
      attributes,
      rgba: backgroundColor,
      durationPredicted: duration,
    }

    return body
  }
  // eslint-disable-next-line consistent-return
  const fetch = async ({ filename, filetype, isAll = true }) => {
    try {
      if (!workspaceId) return new Error('useExportVideo - invalid workspaceId')
      if (!isValidWorkspaceStates) throw new Error('useExportVideo - invalid workspace states')
      const body = getBody({ filename, filetype, isAll })

      setApiState(CALL_STATE.FETCHING)
      await AivatarClient().post(`movies`, body)

      setApiState(CALL_STATE.SUCCESS)
    } catch (error) {
      setApiState(CALL_STATE.ERROR)
      setRes(null)
      return Promise.reject(error)
      // throw new Error(error);
    }
  }

  /** Status */
  const isValidStatus = useMemo(() => res?.status === 200, [res])
  /** data */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data = useMemo(() => {
    if (!isValidStatus) return null
    return res.data
  }, [res, isValidStatus])

  return {
    fetchExportVideo: fetch,
    isLoading,
    isCallSuccess,
    isCallError,
  }
}
