/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnchorHTMLAttributes, ReactNode } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'
import { useCurrentWorkspaceContracted } from '../../../hooks/queries'

type Props = {
  href?: AnchorHTMLAttributes<HTMLAnchorElement>['href']
  target?: AnchorHTMLAttributes<HTMLAnchorElement>['target']
  disabled?: boolean
  children: ReactNode
}

export default function PlanLink({ href, children, target, disabled }: Props) {
  const contracted = useCurrentWorkspaceContracted()

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={twMerge(
        'border-bcblue-500 text-bcblue-500 hover:bg-bcblue-500 grid h-[32px] w-[137px] place-items-center rounded-[50px] border text-[12px] hover:text-white',
        (contracted || disabled) && 'border-gs01-100 text-gs01-100 hover:text-gs01-100 hover:bg-white',
      )}
      href={contracted ? undefined : href}
      target={target}
    >
      <span className="text-button01">{children}</span>
    </a>
  )
}
