import { useTranslation } from 'react-i18next'
import { PlanName } from '../../../@types/common'
import PriceFormat from '../../Text/PriceFormater'

type Props = {
  plan: {
    name: PlanName
    price: number
    vat: number
    amount: number
    displayName: string
  }
}

export default function Receipt({ plan }: Props) {
  const { t } = useTranslation('plan-checkout')

  return (
    <div>
      <table className="w-full [&_tr>:first-child]:pl-[32px] [&_tr>:last-child]:pr-[32px] [&_tr>:last-child]:text-right [&_tr]:pl-[30px]">
        <colgroup>
          <col className="w-[200px]" />
          <col />
          <col className="w-[100px]" />
          <col className="w-[100px]" />
        </colgroup>
        <thead className="h-[45px] rounded-[5px]">
          <tr className="[&_th]:bg-main-4 [&_th]:text-left [&_th]:text-[16px] [&_th]:font-[700]">
            <th className="rounded-l-[5px]">{t('상품')}</th>
            <th>{t('단가')}</th>
            <th>{t('수량')}</th>
            <th className="rounded-r-[5px]">{t('금액')}</th>
          </tr>
        </thead>
        <tbody className="[&_td]:text-gray-3 [&_td]:h-[40px] [&_td]:text-[14px] [&_td]:font-[400]">
          <tr>
            <td translate="no" className="">
              {plan.displayName} {t('월간 구독 플랜')}
            </td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <PriceFormat amount={plan.price} hideSuffix />
            </td>
            <td>1</td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <PriceFormat amount={plan.price} hideSuffix />
            </td>
          </tr>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td />
            <td>{t('부가세')}(10%)</td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <PriceFormat amount={plan.price * plan.vat} hideSuffix />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="bg-main-4 mt-[20px] flex items-center justify-end gap-[15px] rounded-[5px] px-[20px] py-[14px] text-right">
        <span className="text-gray-2 text-[16px] font-[700]">{t('합계')}</span>
        <span className="text-main-1 text-[23px] font-[900]">
          <PriceFormat amount={plan.amount} />
        </span>
      </div>
    </div>
  )
}
