import { TextField } from '@mui/material'
import { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import ExitIcon from '../../../../../../assets/images/global_icon-exit.png'
import useExportAudio from '../../../../../../services/audio/useExportAudio'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
// import IconLoading from '../../../../../../components/Loading/IconLoading'
import useUpdateProject from '../../../../../../services/project/useUpdateProject'
import CreditModal from './CreditModal'
import useSnackbarHandler from '../../../../../../components/Snackbar/useSnackbarHandler'
import {
  isValidBoxesEmptyState,
  isValidSelectedBoxesEmptyState,
  selectedBoxesEstimatedDurationState,
  totalEstimatedDurationState,
} from '../../../../../../recoil/texteditor/editor/selectors'
import { isClickedVideoNavigatorState } from '../../../../../../recoil/workspace/atoms'
import WarningIcon from '../../../../../../assets/images/global_icon-warning-red.png'
import RadioActiveIcon from '../../../../../../assets/images/global_icon-radio-active.png'
import RadioIcon from '../../../../../../assets/images/global_icon-radio.png'
import useExportVideo from '../../../../../../services/video/useExportVideo'
import AivatarButton from '../../../../../../components/buttons/AivatarButton'
import { projectValueState } from '../../../../../../recoil/project/selectors'
import { currentSelectedCharacterState } from '../../../../../../recoil/workspace/selectors'

export default function ExportModal({ type, handleModalClose }) {
  const { t } = useTranslation('project-export')
  /** States */
  const isAudio = useMemo(() => type === 'AUDIO', [type])

  const selectedBoxesEstimatedDuration = useRecoilValue(selectedBoxesEstimatedDurationState)
  const totalEstimatedDuration = useRecoilValue(totalEstimatedDurationState)

  const currentSelectedCharacter = useRecoilValue(currentSelectedCharacterState)
  const creditRatio = {
    AUDIO: 1,
    VIDEO: currentSelectedCharacter.styleType === 'BIDIMENSIONAL' ? 3 : 10,
  }

  const [isConfirm, setIsConfirm] = useState(false)
  const [isAllExport, setIsAllExport] = useState(true)
  const [fetchExportLevel, setFetchExportLevel] = useState(0)
  const [formatSelectedIndex, setFormatSelectedIndex] = useState(0)
  const [textValue, setTextValue] = useState('')

  /** Recoils */

  const isValidSelectedBoxesEmpty = useRecoilValue(isValidSelectedBoxesEmptyState)
  const isValidBoxesEmpty = useRecoilValue(isValidBoxesEmptyState)
  const isClickedVideoNavigator = useRecoilValue(isClickedVideoNavigatorState)
  const backgroundColor = useRecoilValue(projectValueState({ key: 'backgroundColor' }))

  /** Services */
  const { fetchExportAudio, isLoading: isLoadingExportAudio } = useExportAudio()

  const { fetchExportVideo, isLoading: isLoadingExportVideo } = useExportVideo()

  const { fetchProject, isLoading } = useUpdateProject()

  const { showExportSnackbar } = useSnackbarHandler()

  /** Validations */
  const isValidText = useMemo(() => textValue.length >= 2 && textValue.length <= 20, [textValue])
  const isValidExport = useMemo(() => isValidText && !isValidBoxesEmpty, [isValidText, isValidBoxesEmpty])

  const title = useMemo(() => {
    if (isAudio) {
      return t('음성 파일 내보내기')
    }
    return t('영상 파일 내보내기')
  }, [isAudio, t])

  const fileTitle = useMemo(() => {
    if (isAudio) {
      return t('음성')
    }
    return t('영상')
  }, [isAudio, t])

  const fileFormatArray = useMemo(() => {
    if (isAudio) {
      return ['WAV', 'MP3']
    }
    return ['MP4', 'WebM']
  }, [isAudio])

  const calculateCredit = () => {
    const duration = isAllExport ? totalEstimatedDuration : selectedBoxesEstimatedDuration
    return Math.floor((duration / 1000) * creditRatio[type])
  }

  const handleIsAllExport = (isAll) => {
    if (!isValidExport) return
    if (isAll === false && isValidSelectedBoxesEmpty) return

    setIsAllExport(isAll)
    setIsConfirm(true)
  }

  const onClickConfirm = async () => {
    if (!isValidExport) return
    try {
      await fetchProject()
      setFetchExportLevel(1)
    } catch (error) {
      /* empty */
    }
  }

  /**  Fetching Export Handler */
  useAivatarDidMount(() => {
    if (fetchExportLevel !== 1) return
    setFetchExportLevel(0)

    if (isAudio) {
      fetchExportAudio({
        filename: textValue,
        filetype: fileFormatArray[formatSelectedIndex].toLowerCase(),
        isAll: isAllExport,
      })
        .then(() => {
          showExportSnackbar({
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: true,
            isAudio,
          })
          handleModalClose()
        })
        .catch((error) => {
          const snackbar = {
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: false,
            isAudio,
          }
          if (error.response.status === 406) {
            snackbar.message = t('크레딧이 부족하여 내보내기에 실패했습니다.')
          }
          showExportSnackbar(snackbar)
          handleModalClose()
        })
    } else {
      fetchExportVideo({
        filename: textValue,
        filetype: fileFormatArray[formatSelectedIndex].toLowerCase(),
        isAll: isAllExport,
      })
        .then(() => {
          showExportSnackbar({
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: true,
            isAudio,
          })
          handleModalClose()
        })
        .catch((error) => {
          const snackbar = {
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: false,
            isAudio,
          }
          if (error.response.status === 406) {
            snackbar.message = t('크레딧이 부족하여 내보내기에 실패했습니다.')
          }
          showExportSnackbar(snackbar)
          handleModalClose()
        })
    }
  }, [fetchExportLevel])

  /** Effects */
  useAivatarDidMount(() => {
    if (!isAudio && backgroundColor === null) {
      setFormatSelectedIndex(1)
    }
  }, [isAudio, backgroundColor])

  const onValueChange = (e) => {
    setTextValue(e.target.value)
  }

  /** Display */

  const warningComponent = useMemo(() => {
    if (isAudio) return null
    if (isClickedVideoNavigator) return null
    return (
      <div className="flex w-full items-center justify-center">
        <div className="flex w-fit items-center justify-center rounded-full border-[0.5px] border-[#F05E5E] px-[15px] py-[5px]">
          <img className="mr-[5px] h-[20px] w-[20px]" src={WarningIcon} alt="warning" />
          <p className="text-[12px] text-[#F05E5E]">{t('영상 생성을 위한 설정이 없습니다.')}</p>
        </div>
      </div>
    )
  }, [isAudio, isClickedVideoNavigator, t])

  return isConfirm ? (
    <CreditModal
      title={t('{{credits}} 크레딧 차감', { credits: calculateCredit() })}
      subTitle={t('확인을 누르면 {{credits}} 크레딧이 차감됩니다.', { credits: calculateCredit() })}
      setButtonText={t('확인', { ns: 'modal' })}
      onClickSetting={onClickConfirm}
      onClickClose={() => {
        handleModalClose()
      }}
      isPending={isLoading || isLoadingExportAudio || isLoadingExportVideo}
    />
  ) : (
    <div className="aivatar_scroll_wrapper z-50 flex w-full flex-col overflow-hidden overflow-y-auto rounded-md p-[5px]">
      {/* {(isLoadingExportAudio || isLoadingExportVideo || isLoading) && <IconLoading size="80px" />} */}
      <div className="flex w-full items-center justify-between">
        <p className="text-[23px] font-bold text-[#3D3D3D]">{title}</p>
        <button type="button" onClick={handleModalClose}>
          <img className="h-[18px] w-[18px]" src={ExitIcon} alt="exit" />
        </button>
      </div>
      <div className="h-[1px] bg-[#CACACA]" />
      <div className="min-h-[40px]" />

      <p className="mb-[5px] text-[18px] text-[#3D3D3D]">{t('파일 이름')}</p>
      <TextField
        onChange={onValueChange}
        value={textValue}
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            '& fieldset': {
              borderWidth: '2px',
              borderColor: '#3A74F5',
            },
            '&:hover fieldset': {
              borderWidth: '2px',
              borderColor: '#3A74F5',
            },
            '&.Mui-focused fieldset': {
              borderWidth: '2px',
              borderColor: '#3A74F5',
            },
          },
        }}
      />
      <motion.p
        animate={{
          opacity: isValidText ? 0 : 1,
        }}
        className="text-[12px] font-light text-[#F05E5E]"
      >
        {t('파일 이름은 최소 2자부터 20자 까지 입력해주세요.')}
      </motion.p>
      <div className="min-h-[10px]" />
      <p className="mb-[5px] text-[18px] text-[#3D3D3D]">{fileTitle}</p>
      <div className="flex h-[40px] items-center justify-between">
        <button
          type="button"
          className={`${
            formatSelectedIndex === 0 ? 'main_bd border-[1px] bg-white text-[#3D3D3D]' : 'bg-[#FAFAFB] text-[#9F9F9F]'
          } flex h-full w-[48%] cursor-pointer items-center justify-center rounded-md text-[18px] font-[350]`}
          onClick={() => {
            // 배경 없음은 WebM만 가능
            if (!isAudio && backgroundColor === null) {
              return
            }
            setFormatSelectedIndex(0)
          }}
        >
          <img
            className="mr-[6px] h-[16px] w-[16px]"
            src={formatSelectedIndex === 0 ? RadioActiveIcon : RadioIcon}
            alt="radio"
          />
          <p>{fileFormatArray[0]}</p>
        </button>
        <button
          type="button"
          className={`${
            formatSelectedIndex === 1 ? 'main_bd border-[1px] bg-white text-[#3D3D3D]' : 'bg-[#FAFAFB] text-[#9F9F9F]'
          } flex h-full w-[48%] cursor-pointer items-center justify-center rounded-md`}
          onClick={() => {
            setFormatSelectedIndex(1)
          }}
        >
          <img
            className="mr-[6px] h-[16px] w-[16px]"
            src={formatSelectedIndex === 1 ? RadioActiveIcon : RadioIcon}
            alt="radio"
          />
          <p>{fileFormatArray[1]}</p>
        </button>
      </div>
      <div className="min-h-[20px]" />
      {warningComponent}
      <div className="min-h-[20px]" />
      <div className="flex h-[44px] items-center justify-center gap-[20px] font-bold">
        <AivatarButton
          type="button"
          variant="m3"
          className="px-[25px]"
          disabled={isValidSelectedBoxesEmpty || !isValidExport}
          onClick={() => {
            handleIsAllExport(false)
          }}
        >
          {t('선택 내보내기')}
        </AivatarButton>
        <AivatarButton
          type="button"
          variant="m1"
          className="px-[25px]"
          disabled={!isValidExport}
          onClick={() => {
            handleIsAllExport(true)
          }}
        >
          {t('전체 내보내기')}
        </AivatarButton>
      </div>
    </div>
  )
}
