import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PageWithCenterBoxAndLogo from '../../components/PageWithCenterBoxAndLogo'
import TextWithDivider from '../../components/TextWithDivider'
import GoogleButton from '../../components/buttons/GoogleButton'
import ErrMsg from './ErrMsg'
import useAuth from '../../hooks/useAuth'

function Login() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginStatus, setLoginStatus] = useState('init')
  const [validator, setValidator] = useState({ valid: true, msg: '' })

  const { t } = useTranslation('auth-login')
  const { login } = useAuth()

  // eslint-disable-next-line consistent-return
  const loginWithEmail = () => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (!email.match(mailFormat)) {
      setValidator({ valid: false, msg: t('올바른 이메일을 입력해주세요.') })
      return false
    }
    setValidator({ valid: true, msg: '' })
    login({ email, password })
      .then((user) => {
        if (user.enabled === true) {
          navigate('/')
        } else if (user.enabled === false && user.emailVerifiedAt != null) {
          navigate('/user-info-update')
        }
      })
      .catch(() => {
        setLoginStatus('failed')
        setValidator({
          valid: false,
          msg: t('입력하신 이메일 또는 비밀번호를 확인해주세요.'),
        })
      })
  }

  // login.fetchLogin(email, password).then(({ success, data }) => {
  //   if (success === true) {
  //     setUser(data); // 받아온 데이터로 사용자 설정
  //     console.log(user.enabled);
  //     if (user.enabled === false) {
  //       return navigate('/');
  //     }
  //     return navigate("/sign-up-confirm");
  //   } else {
  //     setLoginStatus("failed");
  //     if (data && data.message === "User is disabled") {
  //       setValidator({ valid: false, msg: LGOIN_MESSAGE.VALIDATION_1 });
  //     } else {
  //       setValidator({
  //         valid: false,
  //         msg: LGOIN_MESSAGE.VALIDATION_2
  //       });
  //     }
  //   }
  // })

  return (
    <PageWithCenterBoxAndLogo>
      <input
        className="input mt-[50px]"
        type="email"
        placeholder={t('이메일')}
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
      />
      <input
        className="input mt-[10px]"
        type="password"
        placeholder={t('비밀번호')}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            loginWithEmail()
          }
        }}
      />
      {validator.valid === false ? <ErrMsg>{validator.msg}</ErrMsg> : null}

      <button className="btn-main btn-l mt-[20px]" onClick={loginWithEmail}>
        {t('로그인')}
      </button>
      <Link className="link mt-[20px] w-full" to="/find-pw">
        {t('비밀번호를 잊으셨나요?')}
      </Link>
      <TextWithDivider className="mt-[20px]">{t('또는')}</TextWithDivider>
      <GoogleButton className="mt-[20px]">{t('구글 계정으로 로그인')}</GoogleButton>
      <Link className="link-main mt-[20px]" to="/sign-up">
        {t('회원가입')}
      </Link>
    </PageWithCenterBoxAndLogo>
  )
}

export default Login
