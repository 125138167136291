import { useTranslation } from 'react-i18next'
import useURI from '../../../hooks/useURI'
import useAuth from '../../../hooks/useAuth'

type Props = {
  significant?: boolean
}

function ContactLink({ significant }: Props) {
  const { user } = useAuth()
  const { uri } = useURI()

  const { t } = useTranslation('plan-info-modal')

  return (
    <a
      className="border-bcblue-500 text-bcblue-500 hover:bg-bcblue-500 grid h-[32px] w-[137px] place-items-center rounded-[50px] border text-[12px] hover:text-white"
      href={`${uri['contact-us']}?email=${user?.email}`}
      target="_blank"
      rel="noreferrer"
    >
      <span className="text-button01">{significant ? t('세일즈팀에 문의') : t('문의하기')}</span>
    </a>
  )
}

export default ContactLink
