import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
}

export default function ColumnHeader({ children }: Props) {
  return (
    <th
      scope="col"
      className="border-gray-5 h-[54px] border-l-[1px] px-[33px] text-center align-top font-bold first:border-none first:text-left [&>*]:mx-auto"
    >
      <div className="relative flex flex-col items-center py-[30px]">{children}</div>
    </th>
  )
}
