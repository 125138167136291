import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import env from '../constants/env'
import AivatarClient from '../services/AivatarClient'
import { paymentsQueries } from '../services/payments'
import { workspaceQueries } from '../services/workspace'
import { useCurrentWorkspaceDetailQuery } from './queries'

export default function useChangePaymentMethod() {
  const queryClient = useQueryClient()
  const { i18n } = useTranslation()

  const { data: workspace } = useCurrentWorkspaceDetailQuery()

  const { mutate: mutateToChangePaymentMethod, isPending: isChangingPaymentMethod } = useMutation({
    mutationFn: async (body: any) => AivatarClient().post('/payments/methods', body),
  })

  const noticeUrl = `${env.API_HOST}/webhooks/payment`

  // const showEmailPrompt = () => {
  //   dialog.prompt({
  //     title: '결제 이메일 변경',
  //     message: '결제 영수증이 아래 이메일 주소로 전송됩니다.',
  //     placeholder: 'e-mail주소를 입력해 주세요.',
  //   })
  // }

  // @ts-ignore
  const impCallback = async (resp) => {
    if (resp.success) {
      const body = {
        workspaceId: workspace?.id,
        planId: workspace?.subscription?.plan.id,
        result: {
          pg: 'portone',
          ...resp,
        },
      }

      // todo: 결제 중 로더
      mutateToChangePaymentMethod(body, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: paymentsQueries.methods.queryKey,
          })
          await queryClient.invalidateQueries({
            queryKey: workspaceQueries.detail(workspace.id).queryKey,
          })
        },
        onError: (e) => {
          window.alert(`결제수단 변경 실패 - 관리자에게 문의바랍니다.: ${JSON.stringify(e)}`)
        },
      })
    } else {
      console.log(resp)
      if (resp.error_msg.includes('PAY_PROCESS_CANCELED')) {
        // eslint-disable-next-line no-alert
        return window.alert('사용자가 결제수단 변경을 취소하였습니다.')
      }
      // eslint-disable-next-line no-alert
      return window.alert(`결제수단 변경 실패: ${resp.error_msg}`)
    }

    return undefined
  }

  const changePaymentMethod = () => {
    // @ts-ignore
    const { IMP } = window
    IMP.init(env.IMP)
    // const cuid = uuid()
    const muid = uuid()

    const data = {
      pg: env.PG,
      pay_method: 'card',
      merchant_uid: muid,
      customer_uid: workspace?.subscription?.paymentMethod?.customerUid,
      amount: 0,
      name: env.PAY_NAME,
      popup: true,
      language: i18n.language,
      notice_url: noticeUrl,
    }

    IMP.request_pay(data, impCallback)
  }

  return {
    changePaymentMethod,
    isChangingPaymentMethod,
  }
}
